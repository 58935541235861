<template>
  <div v-if="windowWidth < 700" class="mobile-socials">
    <SocialPreview
      v-for="social in props.socials"
      :key="`mobile-${social.id}`"
      class="mt-6"
      :social="social"
      :hub="hub"
      :content="content"
      :display-post-later-and-edit="props.displayPostLaterAndEdit"
    />
  </div>

  <div class="socials">
    <div class="w-1/2">
      <div v-for="social in leftSideSocials" :key="`left-${social.id}`">
        <SocialPreview
          class="mt-6"
          :social="social"
          :hub="hub"
          :content="content"
          :display-post-later-and-edit="props.displayPostLaterAndEdit"
        />
      </div>
    </div>
    <div class="w-1/2">
      <div v-for="social in rightSideSocials" :key="`right-${social.id}`">
        <SocialPreview
          class="mt-6"
          :social="social"
          :hub="hub"
          :content="content"
          :display-post-later-and-edit="props.displayPostLaterAndEdit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SocialPreview } from './_components/social-preview';
import { computed, ref, onMounted, onUnmounted } from '#imports';
import { ContentDto } from '~/api/contents';
import { HubDto } from '~/api/hubs';

type Props = {
  hub?: HubDto;
  content: ContentDto;
  socials: any[];
  displayPostLaterAndEdit: boolean;
};
const props = defineProps<Props>();

const windowWidth = ref<number>(0);

const onWidthChange = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  windowWidth.value = window.innerWidth;
  window.addEventListener('resize', onWidthChange);
});

onUnmounted(() => window.removeEventListener('resize', onWidthChange));

const leftSideSocials = computed(() => {
  return props.socials.filter((social, index) => index % 2 === 0);
});

const rightSideSocials = computed(() => {
  return props.socials.filter((social, index) => index % 2 !== 0);
});
</script>

<style scoped>
.socials {
  @apply hidden;
}

@media only screen and (min-width: 700px) {
  .socials {
    @apply flex gap-6;
  }

  .mobile-socials {
    @apply hidden;
  }
}
</style>
